import { Link } from "react-router-dom";
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  flex-direction: column;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: black;
  border-top-style: solid;
  border-top-width: 2px;
`
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`
export const ItemWrapper = styled.div`
  flex-direction: column;
`

export const DescriptionWrapperBordered = styled.div`
  flex-direction: column;
  border-left-style: solid;
  border-left-width: 3px;
  border-left-color: black;

  border-right-style: solid;
  border-right-width: 3px;
  border-right-color: black;

  height: 280px;
  text-align: center;
  padding: 0px 5px 0 5px;
`

export const ItemName = styled.h2`
  font-size: 50px;
`

export const DescriptionWrapper = styled.div`
  height: 280px;
  text-align: center;
  padding: 0 5px 0 5px;
`

export const ItemDescription = styled.p``

export const BookButton = styled.button`
  height: 100px;
  width: 160px;
  background-color: #F7C08A;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-size: 15px;
  margin: 30px;
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
`
export const LinkItem = styled(Link)`
`

export const SignUpButton = styled.button`
  align-self: center;
  height: 100px;
  width: 660px;
  background-color: #FD8706;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-size: 20px;
  margin: 30px;
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
`

export const Price = styled.h3`
  font-size: 22px;
`

export const LearnButton = styled.button`
  height: 80px;
  width: 260px;
  background-color: #FD8706;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
  align-self: center;
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
`
// export const LinkItem = styled(Link)``
