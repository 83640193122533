import { Container, Menu, NavUl, NavLi, LiLink, Title, Image, SidebarContainer, Seperator } from './NavBar.styled'
import img from '../assets/images/satserver.png'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Sidebar } from './Sidebar'
export const NavBar = () => {
  return (
      <Container>
        <Title href="/" className="brand-name">
          Bitcoin <br/> Merchant <br/> Services
        </Title>
        <Image src={img} />
        <Menu>
          <NavUl>
            <NavLi>
              <LiLink href="/plans">Pricing</LiLink>
            </NavLi>
            <Seperator />
            <NavLi>
              <LiLink href="/faqs">FAQ</LiLink>
            </NavLi>
          </NavUl>
        </Menu>
      </Container>
    );
}

export default NavBar
