import { MerchantServices } from './MerchantServices'
import { Container, SignUpButton, Title, Description, ServicesWrapper, LinkItem } from './WhyBitcoin.styled'
import { Services } from './Services'

export const WhyBitcoin = () => {
  return (
    <Container>
      <Title>Why Bitcoin?</Title>
      <Description>Bitcoin was created as a way for people to send money over the internet.
      The digital currency was intended to provide an alternative payment system that
      would operate free of central control but otherwise be used just like traditional
      currencies. It is a digital currency that you can buy, sell and exchange directly,
      without an itermediary like a bank. Bitcoin is decentralized, permissionless
      (no-one can be censored), and peer-to-peer.</Description>

      <Description>What the internet did to information, Bitcoin is doing to money - setting
      it free. The creation and control of money will no longer be monopolized in
      the hands of a small group of unaccountable institutions - it is being liberated from
      their control and put into the hands of ordinary people. Bitcoin aims to separate
      money and state, but also, it provides a way for businesses, like yours, to
      transact instantly, at a very low cost, without having to pass through - or
      ask permission from - any intermediary.</Description>

      <LinkItem to="/contact">
        <SignUpButton>Sign Up Now @$30 USD Per Month, Cancel Any Time</SignUpButton>
      </LinkItem>
    </Container>
  )
}
