import { useState } from 'react'
import Slider from 'react-slick'
import { Container } from './Carousel.styled'

import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import screen1 from '../assets/images/screen1.jpeg'
import screen2 from '../assets/images/screen2.jpeg'
import screen3 from '../assets/images/screen3.jpeg'
import screen4 from '../assets/images/screen4.jpeg'
import screen5 from '../assets/images/screen5.jpeg'

const images = [screen1, screen2, screen3, screen4, screen5]

export const Carousel = () => {
  const [imgIndex, setImgIndex] = useState(0)

  const NextArrow = ({onClick}) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    )
  }

  const PrevArrow = ({onClick}) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    )
  }

  const settings = {
    infinite:true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
  return (
  <Container>
    <Slider {...settings}>
      {images.map((img, idx) => (
        <div className={idx === imgIndex ? "slide activeSlide" : "slide activeSlide"}>
          <img src={img} alt={idx} />
        </div>
      ))}
    </Slider>
  </Container>
);
}

export default Carousel
