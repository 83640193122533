import styled from 'styled-components'

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 50px
`

export const Line = styled.div`
  display: flex;
  justify-content: center;
  width: 5px;
  background-color: black;
  margin-left: 25px;
  margin-right: 25px;
  height: 550px;
  align-self: center;
  margin-top: 34px;
`

export const ListWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`

export const ListTitle = styled.h3`
  font-size: 30px
`

export const ListItemSat = styled.div`
  height: 50px;
  width: 300px;
  background-color: #A9D099;
  align-self: center;
  border-style: solid;
  border-width: 3px;
  border-color: black;
  margin-bottom: 5px;
`
export const ListItemTrad = styled.div`
  height: 50px;
  width: 300px;
  background-color: #D98083;
  align-self: center;
  border-style: solid;
  border-width: 3px;
  border-color: black;
  margin-bottom: 5px;
`
export const ListText = styled.p``
