import { useRef } from 'react'
import { Container, Title, Form, NameContainer, Label, MessageContainer, Input, TextArea, InputBtn } from './Contact.styled'
import emailjs from '@emailjs/browser';

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert('message sent successfully...');
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Container>
      <Title>Contact us</Title>

      <Form className="form" ref={form} onSubmit={sendEmail}>

        <NameContainer className="name">
          <Label>Name</Label>
          <Input type='text' placeholder='Name' name='user_name' />
        </NameContainer>
        <NameContainer>
          <Label>Email</Label>
          <Input type='email' placeholder='Email address' name='user_email' />
        </NameContainer>
        <NameContainer>
          <Label>Phone</Label>
          <Input type='phone' placeholder='phone' name='user_phone' />
        </NameContainer>

        <MessageContainer>
        <Label>Message</Label>
          <TextArea name='message' type='text' placeholder='Let us know what we can do to help your business accept payment in Bitcoin'></TextArea>
        </MessageContainer>
        <InputBtn type='submit' value='Submit' id='input-submit' />

     </Form>
    </Container>
  )

}

export default Contact
