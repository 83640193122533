import styled from 'styled-components'
import { Link } from "react-router-dom";

import img from '../assets/images/lightning.jpeg'

export const Background = styled.div`
  height: 100%;
  flex: 1;
  background-image: url(${img});
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: black;
`

export const Title = styled.div`
  color: white;
  font-size: 65px;
  font-weight: bold;
  margin-top: 40px;
`

export const ItemsContainer = styled.div`
  align-self: center;
  align-items: center;
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
`

export const Item = styled.div`
  flex-direction: column;
  margin-right: 60px;
  margin-left: 60px;
`

export const ItemName = styled.div`
  color: white;
  font-size: 30px;
`

export const LearnButton = styled.button`
  height: 80px;
  width: 260px;
  background-color: #FD8706;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 100px;
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
`

export const LinkItem = styled(Link)``
