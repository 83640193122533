import { Link } from "react-router-dom";
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  flex-direction: column;
  height: 100%;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: black;
`
export const Title = styled.h2`
  font-size: 50px;
  text-align: initial;
  margin-left: 15px;
`
export const SignUpButton = styled.button`
  align-self: center;
  height: 100px;
  width: 660px;
  background-color: #FD8706;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-size: 20px;
  margin: 30px;
  justify-content: flex-end;
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
`

export const ItemsContainer = styled.div`
  align-self: center;
  align-items: center;
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
`

export const Item = styled.div`
  flex-direction: column;
`
export const ItemName = styled.div`
  color: black;
  font-size: 30px;
`
export const LinkItem = styled(Link)``
