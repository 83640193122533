import styled from 'styled-components'

export const Container = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
`

export const Title = styled.a`
  text-decoration: none;
  color: black;
  font-size: 1.4rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: initial;
`

export const LiLink = styled.a`
font-size: 20px;
  text-decoration: none;
  display: block;
  width: 100%;
  &:hover {
    background-color: #eee;
  }
  @media screen and (max-width: 768px) {
    color: black;
    // increases the surface area of the anchor tag to span the full width of the menu
    width: 100%;
    padding: 1.5rem 0;
  }
`

export const NavUl = styled.ul`
  display: flex;
  padding: 0;
  @media screen and (max-width: 768px) {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
  }
`

export const NavLi = styled.li`
  list-style-type: none;
  margin: 0 1rem;
  @media screen and (max-width: 768px) {
    text-align: center;
    margin: 0;
  }
`

export const Menu = styled.div`
display: flex;
  margin-left: 25px;
  // margin-left: auto;
`
export const Image = styled.img`
  height: 60px;
  width: 800px;
  object-fit: contain;
`

export const Seperator = styled.div`
 width: 3px;
 background-color: black;
 height: 30px;
`

export const SidebarContainer = styled.div`
 margin-right: 1.5rem;
`
