import { Link } from "react-router-dom";
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  flex-direction: column;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: black;
`

export const Title = styled.h2`
  font-size: 50px;
  text-align: initial;
  margin-left: 15px;
`

export const Description = styled.h3`
  margin-left: 15px;
  font-size: 30px;
  text-align: initial;
  font-weight: lighter;
`

export const Logo = styled.img`
  display: flex;
  height: 170px;
  width: 300px;
  margin-right: 5px;
  object-fit: contain;
`

export const LogoGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 25px;
`

export const SignUpButton = styled.button`
  align-self: center;
  height: 100px;
  width: 660px;
  background-color: #FD8706;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-size: 20px;
  margin: 30px;
  justify-content: flex-end;
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
`
export const LinkItem = styled(Link)``
