import { Container, Title, Description, SignUpButton, Logo, LogoGrid, LinkItem } from './Integrations.styled'
import wordpress from '../assets/images/wordpress.png'
import prestashop from '../assets/images/prestashop.png'
import shopify from '../assets/images/shopify.png'

import adobe from '../assets/images/adobe.svg'
import woocommerce from '../assets/images/woocommerce.png'
import drupal from '../assets/images/drupal.png'

import zapier from '../assets/images/zapier.png'
import shopware from '../assets/images/shopware.png'
import virtuemart from '../assets/images/virtuemart.png'

export const Integrations = () => {
  return (
    <Container>
      <Title>Integrations</Title>
      <Description>You can easily integrate our service with lots of popular e-commerce
      products or, for a small fee, we can facilitate these integrations on your
      behalf - both for the organisations listed, and others too.</Description>
      <LogoGrid>
        <Logo src={shopify} alt="shopify" />
        <Logo src={wordpress} alt="wordpress" />
        <Logo src={prestashop} alt="prestashop" />
      </LogoGrid>

      <LogoGrid>
        <Logo src={adobe} alt="adobe" />
        <Logo src={woocommerce} alt="woocommerce" />
        <Logo src={drupal} alt="drupal" />
      </LogoGrid>


      <LogoGrid>
        <Logo src={zapier} alt="zapier" />
        <Logo src={shopware} alt="shopware" />
        <Logo src={virtuemart} alt="virtuemart" />
      </LogoGrid>

      <LinkItem to="/contact">
        <SignUpButton>Sign Up Now @$30 USD Per Month, Cancel Any Time</SignUpButton>
      </LinkItem>
    </Container>
  )
}
