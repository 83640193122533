import styled from 'styled-components'

export const Container = styled.div`
  display:flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
`

export const Title = styled.h2``

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
`

export const NameContainer = styled.div`
`
export const MessageContainer = styled.div``


export const Label = styled.label`
font-size: 20px;
margin: 5px;
justify-content: center;
display: flex;
`
export const Input = styled.input`
  width: 75%;
`

export const TextArea = styled.textarea`
  width: 75%;
  height: 100px;
`

export const InputBtn = styled.input`
  align-self: center;
  height: 40px;
  width: 260px;
  background-color: #FD8706;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-size: 25px;
  font-weight: bold;
  margin-top: 30px;
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
`
