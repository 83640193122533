import { Container, Title, SignUpButton, RowLayout, LinkItem } from './MerchantServices.styled'
import { Services } from './Services'
import { Carousel } from './Carousel'
export const MerchantServices = () => {
  return (
    <Container>
      <Title>SatServer Merchant Services</Title>
      <RowLayout>
        <Services />
        <Carousel />
      </RowLayout>
      <LinkItem to="/contact">
        <SignUpButton>Sign Up Now @$30 USD Per Month, Cancel Any Time</SignUpButton>
      </LinkItem>
    </Container>
  )
}
