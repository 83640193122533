import { LinkItem, Container, ItemContainer, ItemWrapper, DescriptionWrapper, ItemName, ItemDescription, BookButton, SignUpButton, DescriptionWrapperBordered, Price, LearnButton } from './Plans.styled'
import { Link } from "react-router-dom";

export const Plans = () => {
  return (
    <Container section id="plans">
      <ItemContainer>
        <ItemWrapper>
        <ItemName>FREE</ItemName>
        <DescriptionWrapper>
          <ItemDescription>If you have the skills, the desire and/or
            the time, you can do all this without us,
            and we'd be happy for you to do so :)</ItemDescription>

          <ItemDescription>We'd be delighted to provide a free 30 minute
          consultation to help get you set up.</ItemDescription>

          <ItemDescription>At a basic level, you can simply download a wallet and start
          accepting payment. At a higher level, you can run your
          own server, manage your own node, keep software up
          to date and take control of your own network security.</ItemDescription>
        </DescriptionWrapper>
        <LinkItem to="/contact">
          <BookButton>Book Consultation</BookButton>
        </LinkItem>
        <Price>$0 USD/Month + Your Time & Energy</Price>
        </ItemWrapper>

        <ItemWrapper>
        <ItemName>HOSTED</ItemName>
          <DescriptionWrapperBordered>
        <ItemDescription>Most people don't have the time, the inclination,
        or the skills to manage their own BTC payment services.</ItemDescription>

        <ItemDescription>We'll happily manage it for you - running nodes,
        managing servers, ensuring 100% uptime, keepting all
        your systems and services up to date and ensuring security
        is the best it can be.</ItemDescription>

        <ItemDescription>For this, we charge a small fee of $30USD per month
        and you can cancel at any time.</ItemDescription>
        </DescriptionWrapperBordered>
        <LinkItem to="/contact">
          <SignUpButton>Sign Up Now @$30 USD Per Month, Cancel Any Time</SignUpButton>
        </LinkItem>
        <Price>$30 USD/Month</Price>
        <Link to="/faqs">
          <LearnButton>Learn More</LearnButton>
        </Link>
        </ItemWrapper>

        <ItemWrapper>
        <ItemName>CUSTOM</ItemName>
        <DescriptionWrapper>
        <ItemDescription>Maybe you want help with the initial setup,
          but then want to manage your BTC payment services
          internally?</ItemDescription>

          <ItemDescription>Maybe you need something a little bit more complex than the
          basic merchant services package, and require some custom
          software integration?</ItemDescription>

          <ItemDescription>Whatever your requirements, we'd love to hear from you,
          and we'd be delighted to get you set up anf accepting
          payments in Bitcoin.</ItemDescription>
          </DescriptionWrapper>
          <LinkItem to="/contact">
            <BookButton>Book Consultation</BookButton>
          </LinkItem>
          <Price>$00's - $000's</Price>

          </ItemWrapper>

        </ItemContainer>


    </Container>
  )
}
