import { Container, Title, SignUpButton, ItemsContainer, Item, ItemName, LinkItem } from './OurProcess.styled'
import { BsFillPersonPlusFill } from 'react-icons/bs'
import { FaChevronRight, FaPhoneSquareAlt } from 'react-icons/fa'
import { IoLogoBitcoin } from 'react-icons/io'

export const OurProcess = () => {
  return (
    <Container>
      <Title>Our Process</Title>
      <ItemsContainer>
        <Item>
          <BsFillPersonPlusFill size={100}/>
          <ItemName>Signup + Connect Your Wallet</ItemName>
        </Item>

        <FaChevronRight color={'orange'} size={100} />

        <Item>
          <FaPhoneSquareAlt size={100} />
          <ItemName>Setup Store With Our Help (Call)</ItemName>
        </Item>

        <FaChevronRight color={'orange'} size={100} />

        <Item>
          <IoLogoBitcoin size={100} />
          <ItemName>Start Earning Sats</ItemName>
        </Item>
      </ItemsContainer>

      <LinkItem to="/contact">
        <SignUpButton>Sign Up Now @30 USD Per Month, Cancel Any Time</SignUpButton>
      </LinkItem>
    </Container>
  )
}
