import { Container, Title, Box, BoxTitle, BoxText, SignUpButton, LinkItem } from './Faqs.styled.js'

export const Faqs = () => {
  return (
    <Container>
      <Title>FAQs about SatServer</Title>
      <Box>
        <BoxTitle>Why are you doing this?</BoxTitle>
        <BoxText>Our mission is to help transition the world to a Bitcoin standard.
        There are lots of great companies helping individuals get set up with the
        ability to use Bitcoin, but there's still not enough companies accepting
        it as payment. Our goal is to get a million companies across the world to
        accept payment in Bitcoin, and to exponentially grow the footprint of the
        Bitcoin economy.</BoxText>
      </Box>

      <Box>
        <BoxTitle>What does SatServer do that I can't do myself?</BoxTitle>
        <BoxText>In short-nothing! The Bitcoin ecosystem is robust and open source, so
        if you have the time, the skills and/or the inclination, you can absolutely
        do all this yourself. However, according to our early customers most businesses
        don't have the knowledge or the desire to run their own nodes, manage their
        own servers, stay uBoxText to date with all the various versions of all the various
        bits of software, or any of the other fiddly stuff which goes with managing your
        own BTC payment services, so we thought we'd offer it to the world as a
        paid service :) .</BoxText>
      </Box>

      <Box>
        <BoxTitle>Why should I accept payment in Bitcoin?</BoxTitle>
        <BoxText>Aside from all the reasons listed in our 'Why Bitcoin' page, by
        accepting Bitcoin, you open yourself up to a while new world of consumers.
        Bitcoiners are a generous bunch, and they're keen to support businesses
        that are part of our community. Much like the early days of the internet,
        the early adopters will be the ones to make the most as the world transitions
        away from government currency and towards digital money.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Why not just use a traditional payment provider?</BoxTitle>
        <BoxText>The centralized institutions not only control your coins they also
        charge substantial gees with every transition. With SatServer, you control your
        coins - we have no visibility into your transactions, and unlike the regular
        institutions, we couldn't censor you even if we wanted to (which we don't).
        This is in line with Bitcoin's ethos of being decentralized, peer to peer and permissionless.
        Ww simply host the software that facilitates Bitcoin transactions, it's still
        entirely under your control, and there's only one low monthly fee no matter how many
        transactions you do. The cost for traditional payment providers can easily
        add up to thousands of dollars a year, which now stays in your pocker and
        not the payment processor.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Can I instantly turn BTC into cash?</BoxTitle>
        <BoxText>While we encourage you to hold on to your Bitcoin unless you need the
        cash for short-term needs, it is possible to convert your Bitcoin at any time,
        through a few routes. One option is to sign up to an exchange like Coinbase,
        Gemini or Binance, transfer your Bitcoin there, and instantly turn it into
        whatever currency you prefer. Another option is to make use of the 'auto convert'
        feature, which we will be implementing in the near future.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Why do I need my own seperate wallet?</BoxTitle>
        <BoxText>It's really about security and ensuring that you retain full control of your
        account. We host the servers and software, but you and only you have control of your
        actual funds. Your private keys never touch our servers, so your funds are inaccessible
        to anyone other than you. In the (very rare) event that our service was attacked, your
        funds would be safe, and you could access them if our service was taken down entirely.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Can I just get a wallet & share my address with clients, letting them pay that way, without paying you?</BoxTitle>
        <BoxText>Absolutely, you can! In fact, if you choose to speak to use about
        'free options', this is one we'll talk you through. It's strengths are: it's
        free and super quick & simple to set up. It's downsides are: it becomes a little
        more complicated to create invoices, it makes it a little harder to confirm payment,
        and, most importantly, it's significantly less secure - the risks to your
        anonymity are significant. In short, using this model, every client will pay
        to the same wallet address, making it progressively easier to 'dox' you.
        With SatServer, every transition is made to a new address, which ensures
        your transactions are private. Finally - it's a lot harder to deal with taxes using
        your own wallet. With SatServer, we'll send you a simple document for you
        to share with your accountant to ensure you're compliant with all your local
        taxes.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Explain BTC vs Lightning to me?</BoxTitle>
        <BoxText>BTC is the main chain, and Lightning is a 2nd layer, 'sidechain', that makes transactions
        cheaper and faster. You should always use Lightning if possible, especially with transactions
        below ~$50-$100USD, as it will save your clients money, and the transaction will confirm
        instantly. Either way, the costs of transaction are covered by your client, but if
        using the mainchain, it takes longer to securely confirm. For low-cost items you
        can accept instant payment, even on the mainchain. For higher ticket items, you
        might want to wait 10 minds, for the next block to be mined, or for very large
        items, up to an hour, to ensure that a few clocks have been mined, and the transaction
        is complete and irreversible.
        </BoxText>
      </Box>

      <Box>
        <BoxTitle>Can you see my transactions?</BoxTitle>
        <BoxText>No we cannot, and, because we create a new address for every transaction, it makes
        it harder (but not impossible) for companies like Chain Analysis to track
        you as well.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Is it mobile friendly?</BoxTitle>
        <BoxText>Absolutely, The software will work seamlessly on desktop, tablet
        and mobile devices.</BoxText>
      </Box>

      <Box>
        <BoxTitle>How do taxes and regulations work?</BoxTitle>
        <BoxText>Each country has different rules when it comes to taxing Bitcoin.
        We suggest you speak with your accountant and inform them that you plan to start
        accepting payment in Bitcoin. We can easily supply you with a document that you
        can share with your accountant when tax season comes.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Can I issue refunds?</BoxTitle>
        <BoxText>Yes, very easily. Just send the bitcoin back to the originating
        address. There's a button to execute a refund on the original transaction.</BoxText>
      </Box>

      <Box>
        <BoxTitle>What if I want to cancel?</BoxTitle>
        <BoxText>You can cancel your subscription any time, and we won't take any
        further payments from you. Any subscription fees already spent will be
        in forfeit. Your account will remain active until the end of your billing
        cycle and then it will be deactivated.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Are ChargeBacks possible?</BoxTitle>
        <BoxText>Chargebacks are not possible with Bitcoin. Unlike regular payment
        providers, Bitcoin final settlement takes minutes, sometimes seconds, and
        there is no way for the client to reverse the payment at a later time. This
        means you can ship your product with confidence.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Can I send invoices instantly to customers?</BoxTitle>
        <BoxText>Yes, this is one of the key benefits to our service. We generate a link
        especially for each customer, and once they click, they are taken to a
        live invoice. Conversion from the dollar value to Bitcoin will be done at the
        time of payment, not when the invoice was created, so you are protected from volatility
        risks. That way, both parties get 'fair pay', based on the live price of
        Bitcoin.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Does BTC integrate with my e-commerce site?</BoxTitle>
        <BoxText>Bitcoin payments can be integrated with most content management
        systems. If you need assistance with the integration, we can help for
        a small fee.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Why only BTC? Will you offer other coins?</BoxTitle>
        <BoxText>Bitcoin is based on the proof of work consensus mechanism, meaning it is
        completely decentralized and no one group can control the protocol. Most other
        coins are based on the proof of stake consensus which centralized the control
        of the network. This means the people who own the most coins or the governing
        body overseeing the project can control the protocol. Proof of stake coins are just
        a blockchain version of the current system that Bitcoin is creating an alternative
        for. In short, no, we will only ever offer Bitcoin payments.</BoxText>
      </Box>

      <LinkItem to="/contact">
        <SignUpButton>Sign Up Now @$30 USD Per Month, Cancel Any Time</SignUpButton>
      </LinkItem>

      <h2>FAQs about Bitcoin</h2>
      <Box>
        <BoxTitle>What is Bitcoin?</BoxTitle>
        <BoxText>Bitcoin is a form of digital currency that aims to eliminate the
         need for central authorities such as banks or governments Instead,
         Bitcoin uses blockchain technology to support peer-to-peer transactions
         between users on a decentralized network.</BoxText>
      </Box>

      <Box>
        <BoxTitle>What is a wallet?</BoxTitle>
        <BoxText>This is a device or piece of software which enables you to make
         and receive Bitcoin transactions. It also allows you to 'store' your
         Bitcoin, which is why it's described as a wallet.</BoxText>
      </Box>

      <Box>
        <BoxTitle>What is a blockchain?</BoxTitle>
        <BoxText>A blockchain is a system of recording information in a way that
         makes it difficult or impossible to change, hack, or cheat the system.
        A blockchain is essentially a digital ledger of transactions that is
        duplicated and distributed across the entire network of computer systems
        on the blockchain.</BoxText>
      </Box>

      <Box>
        <BoxTitle>What are SATS?</BoxTitle>
        <BoxText>SATS are to Bitcoin like cents are to dollars. While there are
        100 cents in a dollar, there are 100 MILLION sats in a Bitcoin. They're
        the smallest unit of Bitcoin currency. 1 Sat is equal to 0.00000001 BTC,
        and 1 dollar is currently equivalent to about 6000 SATS.</BoxText>
      </Box>

      <Box>
        <BoxTitle>I've heard it's bad the environment?</BoxTitle>
        <BoxText>Bitcoin uses an energy intensive process called 'proof of work'.
        However, this energy use serves to protect the network, creating a physical
        layer of protection around your money. Even so, it uses 50 times less energy
        than the traditional banking industry, and around 60% of Bitcoin's energy
        usage comes from renewable sources.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Isn't Bitcoin used by criminals?</BoxTitle>
        <BoxText>This is a common myth. Because every transaction is recorded on
        the blockchain, Bitcoin is a uniquely *bad* technology for use by criminals.
        It is estimated that less than 1% of the Bitcoin network usage is for criminal
        purposes.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Isn't Bitcoin used by criminals?</BoxTitle>
        <BoxText>This is a common myth. Because every transaction is recorded on
        the blockchain, Bitcoin is a uniquely *bad* technology for use by criminals.
        It is estimated that less than 1% of the Bitcoin network usage is for criminal
        purposes.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Isn't Bitcoin used by criminals?</BoxTitle>
        <BoxText>This is a common myth. Because every transaction is recorded on
        the blockchain, Bitcoin is a uniquely *bad* technology for use by criminals.
        It is estimated that less than 1% of the Bitcoin network usage is for criminal
        purposes.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Are Bitcoins real?</BoxTitle>
        <BoxText>Bitcoin is 100% digital much like the current monetary system.
        According to an October 2021 piece by Harvard Business Review, over 97%
        of money in circulation is already from online (digital) transactions.
        The digits in your bank account and the numbers in your 401K are digital
        (and often hypothecated). In short, Bitcoin is as real as any other money. </BoxText>
      </Box>

      <Box>
        <BoxTitle>Do I have to buy a whole coin?</BoxTitle>
        <BoxText>Absolutely not! The smallest unit of Bitcoin is the Satoshi, equal
        to 0.00000001 BTC. 1 dollar is currently equal to about 6000 Satoshi(SATS).</BoxText>
      </Box>

      <Box>
        <BoxTitle>Why is Bitcoin so volatile?</BoxTitle>
        <BoxText>Bitcoin's price fluctuates because it is influenced by supply
        and demand, investor and user sentiment, government regulation, and media
        hype. It is a new commodity - the first digital commodity - goin through
        the process of 'monetization'. All these factors work together to create
        price volatility.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Won't governments eventually stop it?</BoxTitle>
        <BoxText>They might try, but they will fail. As Satoshi Nakamoto - the
        creator of Bitcoin - once said: “Governments are good at cutting off the
        heads of a centrally controlled networks like Napster, but pure P2P networks
        like Gnutella and Tor seem to be holding their own.”</BoxText>
      </Box>

      <Box>
        <BoxTitle>What is Central Bank Digital Currency?</BoxTitle>
        <BoxText>In short, Central Bank Digital Currencies (CBDC) are a centralized
        digital fiat currency that is issued by and controlled by the issuing central
        bank. It is a permission-based system with zero anonymity that gives
        governments the ability to impose digital rules and tracking on the currency.
        In other words, CBDCs are the antithesis of Bitcoin.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Is Bitcoin positive for Human Rights?</BoxTitle>
        <BoxText>Bitcoin does not discriminate and provides a level playing field
        for all. Because the traditional financial system easily allows government
        regimes to overreach and freeze their citizens' funds, dissidents fighting
        against the evils of authoritarian regimes use Bitcoin as a platform for
        exercising and defending their rights and freedoms.</BoxText>
      </Box>

      <Box>
        <BoxTitle>Who controls Bitcoin?</BoxTitle>
        <BoxText>Bitcoin is a system of rules without rulers. There's no president
        or CEO, the anonymous founder left many years ago, there's no oversight
        board or ruling council. It's run by its stakeholders - developers, miners,
        node operators, exchanges, users, and everyone else in the community.
        No one person or group can unilaterally make changes to the software that
        runs the network. Therefore, no one person or group can be said to be in
        control of Bitcoin.</BoxText>
      </Box>

      <Box>
        <BoxTitle>What happens when the last coin is mined?</BoxTitle>
        <BoxText>When the supply of Bitcoin reaches 21 million, miners will no
        longer be compensated with 'block rewards'. Miners will earn income from
        transaction processing fees only, rather than how they do now, through a
        combination of block rewards AND mining fees.</BoxText>
      </Box>

      <LinkItem to="/contact">
        <SignUpButton>Sign Up Now @$30 USD Per Month, Cancel Any Time</SignUpButton>
      </LinkItem>
    </Container>
  )
}
