import { Link } from "react-router-dom";
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 20px;
`
export const Title = styled.h2`
  font-size: 50px;
  text-align: initial;
  font-weight: bolder;
`
export const Box = styled.div`
  border-style: solid;
  border-width: 3px;
  border-color: black;
  margin-top: 20px;
`

export const BoxTitle = styled.h4`
  font-size: 25px;
  font-weight: bold;
  text-align: initial;
  margin: 5px 0 0 25px;
`

export const BoxText = styled.p`
  margin: 15px 25px 15px 25px;
  text-align: initial;
`

export const SignUpButton = styled.button`
  align-self: center;
  height: 100px;
  width: 660px;
  background-color: #FD8706;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-size: 20px;
  margin: 30px;
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
`

export const LinkItem = styled(Link)``
