import { Link } from "react-router-dom";
import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: black;
`

export const Title = styled.div`
  font-size: 50px;
  align-self: flex-start;
  font-weight: bold;
  margin: 15px
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 50px
`

export const Line = styled.div`
  display: flex;
  justify-content: center;
  width: 5px;
  background-color: black;
  margin-left: 25px;
  margin-right: 25px;
  height: 550px;
  align-self: center;
  margin-top: 34px;
`

export const ListWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`

export const ListTitle = styled.h3`
  font-size: 30px
`

export const ListItemSat = styled.div`
  height: 50px;
  width: 300px;
  background-color: #A9D099;
  align-self: center;
  border-style: solid;
  border-width: 3px;
  border-color: black;
  margin-bottom: 5px;
`
export const ListItemTrad = styled.div`
  height: 50px;
  width: 300px;
  background-color: #D98083;
  align-self: center;
  border-style: solid;
  border-width: 3px;
  border-color: black;
  margin-bottom: 5px;
`
export const ListText = styled.p``

export const SignUpButton = styled.button`
  align-self: center;
  height: 100px;
  width: 660px;
  background-color: #FD8706;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-size: 20px;
  margin: 30px;
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
`

export const RowLayout = styled.div`
  display: flex;
  flex-direction: row;
`

export const LinkItem = styled(Link)`
`
