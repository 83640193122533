import { Background, Title, ItemsContainer, ItemName, Item, LearnButton, LinkItem } from './Home.styled'
import { Link } from "react-router-dom";
import { BsCreditCard } from 'react-icons/bs'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { HiOutlineArrowsUpDown } from 'react-icons/hi2'
import { FiTarget } from 'react-icons/fi'
export const Home = () => {
  return (
    <Background>
      <Title>ACCEPT PAYMENT IN BITCOIN TODAY</Title>
      <ItemsContainer>
      <Item>
        <BsCreditCard size={100} color={'white'} />
        <ItemName>ZERO <br/> TRANSACTION <br/> FEES</ItemName>
      </Item>
      <Item>
        <AiOutlineClockCircle size={100} color={'white'} />
        <ItemName>INSTANT <br/> SETTLEMENT</ItemName>
      </Item>
      <Item>
        <HiOutlineArrowsUpDown size={100} color={'white'} />
        <ItemName>NO <br/> CHARGE <br/> BACKS</ItemName>
      </Item>
      <Item>
        <FiTarget size={100} color={'white'} />
        <ItemName>FLEXIBLE <br/> PAYMENT <br/> OPTIONS</ItemName>
      </Item>
      </ItemsContainer>
        <Link to="/merchant-services">
        <LearnButton>LEARN <br /> MORE</LearnButton>
        </Link>
    </Background>
  )
}

export default Home
