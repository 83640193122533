import { slide as Menu } from 'react-burger-menu'

export const Sidebar = () => {

  const showSettings = (event) => {
    event.preventDefault()
  }

    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu right>
        <a id="home" className="menu-item" href="/">Home</a>
        <a id="plans" className="menu-item" href="/plans">Pricing</a>
        <a id="about" className="menu-item" href="/merchant-services">Our Services</a>
        <a id="contact" className="menu-item" href="/faqs">FAQs</a>
        <a id="contact" className="menu-item" href="/our-process">Our Process</a>
        <a id="contact" className="menu-item" href="/why-bitcoin">Why Bitcoin</a>
        <a id="contact" className="menu-item" href="/integrations">Integrations</a>
        <a id="contact" className="menu-item" href="/contact">Contact</a>
      </Menu>
    )
}
