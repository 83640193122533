import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Home } from './components/Home'
import { Plans } from './components/Plans'
import { Faqs } from './components/Faqs'
import { MerchantServices } from './components/MerchantServices'
import { OurProcess } from './components/OurProcess'
import { Integrations } from './components/Integrations'
import { WhyBitcoin } from './components/WhyBitcoin'
import { NavBar } from './components/NavBar'
import { Contact } from './components/Contact'
import { Carousel } from './components/Carousel'
import { Sidebar } from './components/Sidebar'

import { Main } from './components/Main'

function App() {
  return (
    <div className="App">
    <NavBar />
    <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />

      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/faqs' element={<Faqs/>} />
        <Route path='/plans' element={<Plans/>} />
        <Route path='/merchant-services' element={<MerchantServices/>} />
        <Route path='/our-process' element={<OurProcess/>} />
        <Route path='/integrations' element={<Integrations/>} />
        <Route path='/why-bitcoin' element={<WhyBitcoin/>} />

      </Routes>
    </div>
  );
}


// <Plans />
// <Faqs />
// <MerchantServices />
// <OurProcess />
// <Integrations />
// <WhyBitcoin />


// <NavBar />
// <Home />
// <Plans />
// <Faqs />
// <MerchantServices />
// <OurProcess />
// <Integrations />
// <WhyBitcoin />
// <Routes>
//   <Route path='/home' element={<Home/>} />
//   <Route path='/plans' element={<Plans/>} />
// </Routes>
// </div>
export default App;
