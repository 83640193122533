import { ListTitle, ListContainer, ListWrapper, ListItemSat, ListItemTrad, Line, ListText } from './Services.styled'
export const Services = () => {
  return (
    <ListContainer>
      <ListWrapper>
        <ListTitle>SatServer</ListTitle>
        <ListItemSat>
          <ListText>Zero Transaction Fees</ListText>
        </ListItemSat>

        <ListItemSat>
          <ListText>No ChargeBacks</ListText>
        </ListItemSat>

        <ListItemSat>
          <ListText>Instant Settlement</ListText>
        </ListItemSat>

        <ListItemSat>
          <ListText>Full Autonomy</ListText>
        </ListItemSat>

        <ListItemSat>
          <ListText>Private Transactions</ListText>
        </ListItemSat>

        <ListItemSat>
          <ListText>Unlimited Payments, 1 Montly Fee</ListText>
        </ListItemSat>

        <ListItemSat>
          <ListText>Easy Global Payments</ListText>
        </ListItemSat>

        <ListItemSat>
          <ListText>Integrated Tipping, no extra cost</ListText>
        </ListItemSat>

        <ListItemSat>
          <ListText>Easy Integration</ListText>
        </ListItemSat>
      </ListWrapper>
      <Line/>

      <ListWrapper>

        <ListTitle>Traditional Providers</ListTitle>

        <ListItemTrad>
          <ListText>1-2% Per Transaction</ListText>
        </ListItemTrad>

        <ListItemTrad>
          <ListText>ChargeBacks Common</ListText>
        </ListItemTrad>

        <ListItemTrad>
          <ListText>1-3 Days Settlement</ListText>
        </ListItemTrad>

        <ListItemTrad>
          <ListText>Risk of DePlatforming</ListText>
        </ListItemTrad>

        <ListItemTrad>
          <ListText>Monitored Transactions</ListText>
        </ListItemTrad>

        <ListItemTrad>
          <ListText>More Sales = More Costs</ListText>
        </ListItemTrad>

        <ListItemTrad>
          <ListText>Foreign Sales = Higher Costs</ListText>
        </ListItemTrad>

        <ListItemTrad>
          <ListText>Tipping Features Cost More</ListText>
        </ListItemTrad>

        <ListItemTrad>
          <ListText>Complex/Costly Integration</ListText>
        </ListItemTrad>
      </ListWrapper>
    </ListContainer>
  )
}

export default Services
